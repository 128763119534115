import React from "react"
import { Link } from "gatsby"
import PardeepMeghwar from "../../assets/images/scientist/Pardeep-Meghwar.png"
import AhmedAli from "../../assets/images/scientist/Ahmed-Ali-1.png"
import AmarSuthar from "../../assets/images/scientist/Amar.png"
import KhanjiPir from "../../assets/images/scientist/KhanjiPir.jpg"
import SuneelKumar from "../../assets/images/scientist/Suneel.png"
import HishmatRai from "../../assets/images/scientist/Hishmat-Rai.png"
import KhatuMal from "../../assets/images/scientist/Khatumal.jpg"
import Beerbal from "../../assets/images/scientist/beerbal.png"
import DanishRaaz from "../../assets/images/scientist/Danish-Raaz.png"
import SumairJaffar from './../../assets/images/scientist/Sumair-Jaffar.jpg' 
import AkashSukhani from './../../assets/images/scientist/Akash-Sukhani.jpg'
const TeamStyleOne = () => {
  const teamMembers = [
    {
      teamMemberName: "Pardeep Meghwar",
      teamMemberPosition: "Founder & CEO",
      teamMemberPicture: PardeepMeghwar,
      linkdinLink:'https://pk.linkedin.com/in/pardeepmeghwar'
    },
    {
      teamMemberName: "Ahmed Ali",
      teamMemberPosition: "Co-Founder & Chairman",
      teamMemberPicture: AhmedAli,
      linkdinLink:'https://www.linkedin.com/in/ahmed-ali263'
      
    },
    {
      teamMemberName: "Sumair Jaffar",
      teamMemberPosition: "Vice Chairman",
      teamMemberPicture: SumairJaffar,
      linkdinLink:''

    },
    {
      teamMemberName: "Danish Raaz",
      teamMemberPosition: "Lead Trainer",
      teamMemberPicture: DanishRaaz,
      linkdinLink:'https://pk.linkedin.com/in/danishraaz'

    },
    {
      teamMemberName: "Akash Sukhani",
      teamMemberPosition: "Manager",
      teamMemberPicture: AkashSukhani,
      linkdinLink:'https://www.linkedin.com/in/akash-sukhani-040347159/'

    },
    {
      teamMemberName: "Amar Suthar",
      teamMemberPosition: "Frontend Developer",
      teamMemberPicture: AmarSuthar,
      linkdinLink:'https://www.linkedin.com/in/amar-shivani-021a0a17b/'

    },
    {
      teamMemberName: "Hishmat Rai",
      teamMemberPosition: "Web Developer",
      teamMemberPicture: HishmatRai,
      linkdinLink:'https://www.linkedin.com/in/hishmat-rai-787737170/'

    },
    {
      teamMemberName: "Beerbal",
      teamMemberPosition: "Web Developer",
      teamMemberPicture: Beerbal,
      linkdinLink:'https://www.linkedin.com/in/beerbal-kumar-7893a418b/'

    },
    {
      teamMemberName: "Suneel Kumar",
      teamMemberPosition: "Mobile App Developer",
      teamMemberPicture: SuneelKumar,
      linkdinLink:'https://www.linkedin.com/in/suneel-kumar-b4b0a117b/'

    },

    {
      teamMemberName: "Khatu Mal",
      teamMemberPosition: "Mobile App Developer",
      teamMemberPicture: KhatuMal,
      linkdinLink:'https://www.linkedin.com/in/khatu-mal-75bb8318b/'

    },

    {
      teamMemberName: "Khanji Pir",
      teamMemberPosition: "Graphic Designer",
      teamMemberPicture: KhanjiPir,
      linkdinLink:'https://www.linkedin.com/in/khanji-pir714131216/'

    },
  ]
  return (
    <div className="scientist-area bg-color pt-100 pb-70">
      <div className="container">
        <div className="row">
          {teamMembers.map((val, i) => {
            return (
              <div className="col-lg-3 col-sm-6">
                <div className="single-scientist-box">
                  <div className="image">
                    <img src={val.teamMemberPicture} alt="about" />
                  </div>
                  <div className="content">
                    <h3>{val.teamMemberName}</h3>
                    <span>{val.teamMemberPosition}</span>

                    <ul className="social">
                      {/* <li>
                        <Link to="#" className="d-block" target="_blank">
                          <i className="bx bxl-facebook"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="d-block" target="_blank">
                          <i className="bx bxl-twitter"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="d-block" target="_blank">
                          <i className="bx bxl-instagram"></i>
                        </Link>
                      </li> */}
                      <li>
                        <Link to={val.linkdinLink} className="d-block" target="_blank">
                          <i className="bx bxl-linkedin"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default TeamStyleOne
